import { YesNoString, YesNoStringYesDefault } from "../netsapiens/types.gen";

/** Migration Constants */
export const DOMAIN_DATA_TYPE = 'Meta_Global_DomainInformation';
export const DEPARTMENTS_DATA_TYPE = 'Msrb_BusinessGroup_ChildrenList_Department';
export const SUBSCRIBERS_DATA_TYPE = 'Msrb_BusinessGroup_ChildrenList_Subscriber';
export const PHONES_DATA_TYPE = 'Meta_BusinessGroup_ChildrenList_ManagedDevice';
export const AUTO_ATTENDANT_USER_TYPE = 'AA';
export const LETTERS_BASE_ASCII_CODE = 97;
/** Domain Data Constants */
export const DOMAIN_DEFAULT_SSO = YesNoString.NO;
export const DOMAIN_DEFAULT_DIAL_PERMISSION = 'us-canada';
export const DOMAIN_DEFAULT_TIMEZONE = '';
export const DOMAIN_DEFAULT_CALLER_ID = 0;
export const DOMAIN_DEFAULT_CALLER_NAME = '';
export const DOMAIN_DEFAULT_AREA_CODE = 0;
export const DOMAIN_DEFAULT_RMOH = YesNoString.YES;
export const DOMAIN_DEFAULT_SENDER_EMAIL = 'no-reply@udtonline.com';
export const DOMAIN_DEFAULT_SERVER = 'us-east1 (core1-nsvltn)';
export const DOMAIN_DEFAULT_VOICEMAIL = true;
export const USER_DEFAULT_EMAIL_PREFIX = 'placeholder@';
export const USER_DEFAULT_SCOPE = '';
export const USER_DEFAULT_VOICEMAIL = YesNoStringYesDefault.YES;
export const BASIC_USER_SCOPE = 'Basic User';
export const SIMPLE_USER_SCOPE = 'Simple User';
export const ADVANCED_USER_SCOPE = 'Advanced User';
export const NO_PORTAL_USER_SCOPE = 'No Portal';
export const ATTENDANT_DEFAULT_SCOPE = NO_PORTAL_USER_SCOPE;
export const ATTENDANT_DEFAULT_EMAIL = 'No Email';
export const PHONE_DEFAULT_ENABLED = 'false';
export const PHONE_USER_DIAL_RULE_APP = 'user';
