export const removeWhiteSpaces = (text: string) => {
  return text.replace(/\s/g, '');
};

export const phraseHasAllWords = (phrase: string, words: string[]): boolean => {
  const indexes: number[] = [];
  const lowerPhrase = phrase.toLowerCase();
  words.forEach((word) =>
    indexes.push(lowerPhrase.indexOf(word.toLowerCase()))
  );
  return indexes.every((index) => index >= 0);
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};
