import { RequestResult } from '@hey-api/client-axios';
import {
  DevicesService,
  DialruleApplication,
  DomainsService,
  ErrorResponse,
  PhoneNumbersService,
  PhonesMacsService,
  Scopes,
  SitesService,
  Synchronous,
  TimeZone,
  UsersService,
  YesNoString,
  YesNoStringYesDefault,
} from '../../netsapiens';
import { NetsapiensData, Return } from '../../models/wizard-models';
import { removeWhiteSpaces } from '../text/text.utils';
import {
  DOMAIN_DEFAULT_DIAL_PERMISSION,
  DOMAIN_DEFAULT_RMOH,
  DOMAIN_DEFAULT_SENDER_EMAIL,
  DOMAIN_DEFAULT_SSO,
  USER_DEFAULT_EMAIL_PREFIX,
} from 'utils/src/utils';

export enum ServiceKey {
  domain = 'DOMAIN',
  site = 'SITE',
  user = 'USER',
  phone = 'PHONE',
  device = 'DEVICE',
}
const createMap: {
  [key: string]: (
    params: NetsapiensData,
    domainId?: string
  ) => RequestResult<Return>;
} = {
  DOMAIN: async (params) => {
    return await DomainsService.createDomain({
      // path: { server: 'preferredServer' in params ? params.preferredServer : '~' }, // to add when server values provided
      body: {
        ...params,
        synchronous: Synchronous.YES,
        domain: params.domain ?? '',
        reseller: 'reseller' in params ? params.reseller : '~',
        'time-zone':
          'timezone' in params ? (params.timezone as TimeZone) : undefined,
        'area-code': 'areaCode' in params ? params.areaCode : undefined,
        'caller-id-number': 'callerId' in params ? params.callerId : undefined,
        'caller-id-name':
          'callerName' in params ? params.callerName : undefined,
        'dial-policy':
          'dialPermission' in params
            ? params.dialPermission
            : DOMAIN_DEFAULT_DIAL_PERMISSION,
        'voicemail-enabled':
          'voicemail' in params ? (params.voicemail as YesNoString) : undefined,
        'email-send-from-address': DOMAIN_DEFAULT_SENDER_EMAIL,
        'single-sign-on-enabled': DOMAIN_DEFAULT_SSO,
        'music-on-hold-enabled': DOMAIN_DEFAULT_RMOH,
        'music-on-hold-randomized-enabled': DOMAIN_DEFAULT_RMOH,
      },
    });
  },
  SITE: async (params, domainId) => {
    const siteName = 'site' in params && params.site ? params.site : '';
    return await SitesService.postDomainsByDomainSites({
      path: { domain: domainId ?? '' },
      body: {
        ...params,
        synchronous: Synchronous.YES,
        site: siteName,
        'caller-id-number': 'callerId' in params ? params.callerId : undefined,
        'time-zone':
          'timezone' in params ? (params.timezone as TimeZone) : undefined,
        'area-code': 'areaCode' in params ? params.areaCode : undefined,
        'login-username': `${removeWhiteSpaces(siteName)}@${domainId}`,
      },
    });
  },
  USER: async (params, domainId) =>
    await UsersService.createUser({
      path: {
        domain: domainId ?? '',
      },
      body: {
        user:
          'extension' in params && params.extension
            ? params?.extension.toString()
            : '',
        'name-first-name': 'firstName' in params ? params.firstName : '',
        'name-last-name': 'lastName' in params ? params.lastName : '',
        'email-address':
          'email' in params && params.email
            ? params.email
            : USER_DEFAULT_EMAIL_PREFIX + domainId,
        'user-scope': Scopes.BASIC_USER,
        'voicemail-enabled':
          'voicemail' in params
            ? (params.voicemail as YesNoStringYesDefault)
            : YesNoStringYesDefault.YES,
        'caller-id-number':
          'callerId' in params && params.callerId
            ? params.callerId
            : undefined,
        site: 'site' in params ? params.site : '',
      },
    }),
  PHONE: async (params, domainId) => {
    const user =
      'dialRuleTranslationDestinationUser' in params &&
      params.dialRuleTranslationDestinationUser
        ? params.dialRuleTranslationDestinationUser.trim()
        : '';
    return await PhoneNumbersService.createPhonenumber({
      path: {
        domain: domainId ?? '',
      },
      body: {
        phonenumber:
          'phoneNumber' in params && params.phoneNumber
            ? params.phoneNumber
            : '',
        'dial-rule-application':
          user === ''
            ? DialruleApplication.AVAILABLE_NUMBER
            : DialruleApplication.TO_USER,
        'dial-rule-translation-destination-user': user,
      },
    });
  },
  DEVICE: async (params, domainId) => {
    const mac = 'mac' in params && params.mac ? params.mac : '';
    const model = 'model' in params && params.model ? params.model : '';
    const phoneMacResponse = await PhonesMacsService.postDomainsByDomainPhones({
      path: {
        domain: domainId ?? '',
      },
      body: {
        mac: mac,
        model: model,
      },
    });
    if ('userId' in params && params.userId) {
      return await DevicesService.createDevice({
        path: {
          domain: domainId ?? '',
          user: params.userId,
        },
        body: {
          device: params.userId + params.deviceSuffix,
          'device-provisioning-mac-address': mac,
          'device-models-model': model,
        },
      });
    } else {
      return phoneMacResponse;
    }
  },
};
export function isError(response: Return): response is ErrorResponse {
  const error = response as ErrorResponse;
  return !!(error.code && error.message);
}
export async function create(
  serviceKey: ServiceKey,
  body: NetsapiensData | NetsapiensData[],
  domainId?: string
): Promise<NetsapiensData | NetsapiensData[] | null> {
  const service = createMap[serviceKey];
  if (!service) {
    throw new Error('Service not enabled');
  }

  if (!Array.isArray(body)) {
    const response = await service(body, domainId);
    if ('error' in response && isError(response.error as ErrorResponse)) {
      return {
        ...body,
        error: response.error as ErrorResponse,
      };
    } else {
      return null;
    }
  }

  const responses = await Promise.allSettled(
    body.map(async (b) => service(b, domainId))
  );

  const errors = responses
    .map((response, index) => {
      if (response.status === 'rejected') {
        return {
          ...body[index],
          error: {
            code: 400,
            message: JSON.stringify(response.reason),
          },
        };
      }
      if (response.status === 'fulfilled') {
        if (
          'error' in response.value &&
          isError(response.value.error as ErrorResponse)
        ) {
          return {
            ...body[index],
            error: response.value.error as ErrorResponse,
          };
        }
      }
      return;
    })
    .filter((response) => !!response);

  if (errors.length > 0) {
    return errors;
  }
  return null;
}
