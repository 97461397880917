import { NetsapiensUser, VALID_USER_SCOPES } from '@voice/utils';

export const validateUsers = (users: NetsapiensUser[]): boolean => {
  let errorFound = false;
  const validEmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  for (let i = 0; i < users.length; i++) {
    const messages: string[] = [];
    const user: NetsapiensUser = users[i];
    if (!user.site) {
      messages.push('Site is required');
    }

    if (user.extension === undefined || user.extension === null) {
      messages.push('Extension is required');
    } else if (isNaN(user.extension)) {
      messages.push('Extension is invalid');
    } else if (user.extension.toString().length < 3) {
      messages.push('Extension should be at least 3 digits');
    } else if (
      users.some(
        (item, index) => item.extension === user.extension && i !== index
      )
    ) {
      messages.push(
        'There is another user with the same extension in the list'
      );
    }

    if (
      user.firstName === undefined ||
      user.firstName === null ||
      user.firstName.trim().length === 0
    ) {
      messages.push('First name is required');
    }

    if (
      user.lastName === undefined ||
      user.lastName === null ||
      user.lastName.trim().length === 0
    ) {
      messages.push('Last name is required');
    }

    if (user.email && !validEmailRegex.test(user.email)) {
      messages.push('Invalid email address');
    }

    if (
      user.scope === undefined ||
      user.scope === null ||
      user.scope.trim().length === 0
    ) {
      messages.push('Scope is required');
    } else if (VALID_USER_SCOPES.indexOf(user.scope) < 0) {
      messages.push('Scope is invalid');
    }

    if (messages.length > 0) {
      user.validationMessages = messages;
      errorFound = true;
    } else user.validationMessages = undefined;
  }
  return errorFound;
};
