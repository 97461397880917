import { NetsapiensSite } from '../models';
import { VALID_US_TIMEZONES } from '../models';
import { validateUSAPhoneNumber } from './phone-numbers.validator';

export const validateSites = (sites: NetsapiensSite[]): boolean => {
  let errorFound = false;
  for (let i = 0; i < sites.length; i++) {
    const messages: string[] = [];
    const site: NetsapiensSite = sites[i];
    if (!site.site || !/^[a-zA-Z0-9 ._-]*$/.test(site.site)) {
      messages.push('Invalid site name');
    }
    if (site.areaCode === undefined || site.areaCode === null || site.areaCode === 0) {
      messages.push('Area code is required');
    } else if (isNaN(site.areaCode)) {
      messages.push('Area code is not valid');
    } else if (site.areaCode.toString().length !== 3) {
      messages.push('Area code should be 3 digits');
    }
    if (site.callerId === undefined || site.callerId === null || site.callerId === 0) {
      messages.push('Caller ID is required');
    } else if (isNaN(site.callerId)) {
      messages.push('Caller ID is not valid');
    } else {
      const usaPhoneInvalidMessage = validateUSAPhoneNumber(
        site.callerId.toString()
      );
      if (usaPhoneInvalidMessage) {
        messages.push(usaPhoneInvalidMessage);
      }
    }
    if (
      site.timezone === undefined ||
      site.timezone === null ||
      site.timezone.trim().length === 0
    ) {
      messages.push('Timezone is required');
    } else if (VALID_US_TIMEZONES.indexOf(site.timezone) < 0) {
      messages.push('Timezone is invalid');
    }
    if (sites.some((item, index) => item.site === site.site && i !== index)) {
      messages.push('There is a duplicate of this site name in the list');
    }
    if (messages.length > 0) {
      site.validationMessages = messages;
      errorFound = true;
    } else site.validationMessages = undefined;
  }
  return errorFound;
};
