/* eslint-disable @typescript-eslint/no-explicit-any */

export const findeIndexByKey = (array: any[], value: any, key: string): number => {
    return array.findIndex(item => item[key] === value[key]);
}
export const editElementFromArrayByKey = (array: any[], value: any, key: string) => {
    const editingIndex = findeIndexByKey(array, value, key);
    const arrayCopy = [...array];
    arrayCopy[editingIndex] = value;
    return [...arrayCopy];
}

export const deleteElementFromArrayByKey = (array: any[], value: any, key: string) => {
    const index = findeIndexByKey(array, value, key);
    const arrayCopy = [...array];
    arrayCopy.splice(index, 1);
    return [...arrayCopy];
}