import { NetsapiensDevice } from '../models';

export const validateDevices = (
  devices: NetsapiensDevice[]
): boolean => {
  let errorFound = false;
  for (let i = 0; i < devices.length; i++) {
    const messages: string[] = [];
    const device: NetsapiensDevice = devices[i];
    if (!/[0-9A-F]{12}$/.test(device.mac)) {
      messages.push('Invalid MAC Address');
    }
    if (
      devices.some(
        (otherDevice, index) =>
          otherDevice.mac === device.mac && i !== index
      )
    ) {
      messages.push('There is a duplicate of this MAC Address in the list');
    }
    if (device.deviceSuffix && !/^[a-z]{1,3}$/.test(device.deviceSuffix)) {
      messages.push('Invalid device ID');
    }
    if (messages.length > 0) {
      device.validationMessages = messages;
      errorFound = true;
    } else device.validationMessages = undefined;
  }
  return errorFound;
};
