import { NetsapiensPhoneNumber } from '../models';
import { isValidUSAPhoneNumber } from './usa-phone-number.validator';

export const validateUSAPhoneNumber = (phoneNumber: string): string | null => {
  if (!/^\d+$/.test(phoneNumber)) {
    return 'Invalid phone number';
  }
  if (!isValidUSAPhoneNumber(phoneNumber)) {
    return 'Invalid USA phone number';
  }
  return null;
}

export const validatePhoneNumbers = (
  phoneNumbers: NetsapiensPhoneNumber[]
): boolean => {
  let errorFound = false;
  for (let i = 0; i < phoneNumbers.length; i++) {
    const messages: string[] = [];
    const phoneNumber: NetsapiensPhoneNumber = phoneNumbers[i];
    const usaPhoneInvalidMessage = validateUSAPhoneNumber(phoneNumber.phoneNumber);
    if (usaPhoneInvalidMessage) {
      messages.push(usaPhoneInvalidMessage);
    }
    if (
      phoneNumbers.some(
        (number, index) =>
          number.phoneNumber === phoneNumber.phoneNumber && i !== index
      )
    ) {
      messages.push('There is a duplicate of this phone number in the list');
    }
    if (messages.length > 0) {
      phoneNumber.validationMessages = messages;
      errorFound = true;
    } else phoneNumber.validationMessages = undefined;
  }
  return errorFound;
};
